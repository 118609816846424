import { useTailwindTheme } from '@src/modules/shared/hooks/use-tailwind-theme';
import type { ReactElement } from 'react';
import { Fragment, useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

interface LoadingIndicatorProps {
	overlay?: boolean;
	delay?: number;
	size?: number;
}

export function LoadingIndicator({
	overlay = false, //
	delay = 250,
	size = 12,
}: LoadingIndicatorProps): ReactElement | null {
	const [show, setShow] = useState(false);
	const theme = useTailwindTheme() as any;
	let color = '#000000';

	if ('primary' in (theme?.colors || {})) {
		if ('DEFAULT' in theme.colors.primary) {
			color = theme.colors.primary.DEFAULT;
		}
	}

	useEffect(() => {
		const timeout = setTimeout(() => setShow(true), delay);
		return () => void clearTimeout(timeout);
	}, [delay]);

	if (!show) {
		return null;
	}

	if (overlay) {
		return (
			<Fragment>
				<div className="absolute inset-0 z-[5000] bg-white/80" />
				<div className="absolute inset-0 z-[5001] flex items-center justify-center">
					<PulseLoader color={color} size={size} />
				</div>
			</Fragment>
		);
	}

	return (
		<div className="absolute inset-0 z-[5001] flex items-center justify-center">
			<PulseLoader color={color} size={size} />
		</div>
	);
}
