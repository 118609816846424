import type { StoreCartsRes, StoreCompleteCartRes } from '@medusajs/medusa';
import type { StorePostCartsCartReq } from '@medusajs/medusa';
import type { PricedShippingOption } from '@medusajs/medusa/dist/types/pricing';
import config from '@src/lib/config';
import { useRegion } from '@src/lib/medusa/hooks/use-region';
import { medusaClient as medusa } from '@src/lib/medusa/medusa-client';
import { queryKeys } from '@src/lib/react-query/query-keys';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSessionStorage } from 'usehooks-ts';

type Cart = StoreCartsRes['cart'];

interface AddLineItemOptions {
	quantity: number;
	variantId: string;
}

interface UpdateLineItemOptions {
	quantity: number;
	lineItemId: string;
}

interface RemoveLineItemOptions {
	lineItemId: string;
}

interface CompleteOrderArgs {
	paymentProvider: string;
}

interface UseCartResult {
	cart?: Cart;
	resetCart: () => void;
	updateCart: (options: StorePostCartsCartReq) => Promise<Cart>;
	shippingMethods?: PricedShippingOption[];
	addLineItem: (options: AddLineItemOptions) => Promise<Cart>;
	updateLineItem: (options: UpdateLineItemOptions) => Promise<Cart>;
	removeLineItem: (options: RemoveLineItemOptions) => Promise<Cart>;
	addShippingMethod: (id: string) => Promise<void>;
	createPaymentSessions: UseMutateAsyncFunction<void>;
	completeOrder: UseMutateAsyncFunction<StoreCompleteCartRes, unknown, CompleteOrderArgs>;
}

function throwIfCartUnavailable(cart?: Cart) {
	if (!cart?.id) throw new Error('Cart not available');
}

export function useCart(): UseCartResult {
	const [cartId, setCartId] = useSessionStorage<string>('medusa-cart-id', '');
	const [, setOrderId] = useSessionStorage<string>('medusa-order-id', '');
	const queryClient = useQueryClient();
	const { region } = useRegion();

	const cart = useQuery<Cart>(
		[queryKeys.cart, cartId], //
		async () => {
			if (cartId) {
				try {
					// Get the existing cart if possible
					const { cart } = await medusa.carts.retrieve(cartId);
					await queryClient.invalidateQueries([queryKeys.paymentSessions]);
					await queryClient.invalidateQueries([queryKeys.shippingOptions]);
					return cart;
				} catch (e) {
					// Log error but fallback to create cart
					console.error('Error getting cart', e);
				}
			}

			// Create cart if no cart id is found
			const { cart } = await medusa.carts.create({
				region_id: region || config.defaultRegion,
				sales_channel_id: config.defaultSalesChannel,
			});

			await queryClient.invalidateQueries([queryKeys.paymentSessions]);
			await queryClient.invalidateQueries([queryKeys.shippingOptions]);
			setCartId(cart.id);

			return cart;
		},
	);

	const shippingMethods = useQuery<PricedShippingOption[]>(
		[queryKeys.shippingOptions, cartId, JSON.stringify(cart)], //
		async () => {
			if (!cartId) return [];
			const { shipping_options } = await medusa.shippingOptions.listCartOptions(cartId || '');
			return shipping_options || [];
		},
		{
			enabled: !!cart?.data?.shipping_address,
			placeholderData: [],
		},
	);

	const resetCart = useCallback(async () => {
		setCartId('');
		await queryClient.invalidateQueries([queryKeys.cart]);
	}, [queryClient, setCartId]);

	const createPaymentSessions = useMutation(
		[cart?.data?.payment_sessions, cart.data?.updated_at, cartId], //
		async () => {
			if (cart.data?.id) {
				if (cart.data?.payment_sessions?.length < 1) {
					const { cart: newCart } = await medusa.carts //
						.createPaymentSessions(cart?.data?.id || '');

					queryClient.setQueryData(
						[queryKeys.cart, cartId],
						newCart, //
					);
				}
			}
		},
	);

	const addLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: AddLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { cart: newCart } = await medusa.carts.lineItems //
				.create(cart.data!.id, {
					quantity: options.quantity,
					variant_id: options.variantId,
				});

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId], //
				newCart,
			);

			return newCart;
		},
	);

	const updateLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: UpdateLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { lineItemId } = options;
			const { cart: newCart } = await medusa.carts.lineItems //
				.update(cart.data!.id, lineItemId, {
					quantity: options.quantity,
				});

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	const removeLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: RemoveLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { lineItemId } = options;
			const { cart: newCart } = await medusa.carts.lineItems //
				.delete(cart.data!.id, lineItemId);

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	const updateCart = useMutation(
		[cart.data?.updated_at, cartId], //
		async (data: StorePostCartsCartReq) => {
			throwIfCartUnavailable(cart.data);

			const { cart: newCart } = await medusa.carts //
				.update(cartId, data);

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	const addShipping = useMutation(
		[cart.data?.updated_at, cartId], //
		async (id: string) => {
			throwIfCartUnavailable(cart.data);

			const opts = { option_id: id };
			await medusa.carts.addShippingMethod(cart.data!.id, opts);
			await queryClient.invalidateQueries([queryKeys.cart]);
		},
	);

	const completeOrder = useMutation([cart.data?.updated_at, cartId], async ({ paymentProvider }: CompleteOrderArgs) => {
		throwIfCartUnavailable(cart.data);

		const opts = { provider_id: paymentProvider };
		await medusa.carts.setPaymentSession(cart.data!.id, opts);
		const result = await medusa.carts.complete(cart.data!.id);

		if (result.type === 'order') {
			setOrderId(result.data.id);
		}

		await queryClient.invalidateQueries([queryKeys.cart]);
		return result as StoreCompleteCartRes;
	});

	return {
		cart: cart.data,
		resetCart,
		updateCart: updateCart.mutateAsync,
		shippingMethods: shippingMethods.data,
		addLineItem: addLineItem.mutateAsync,
		updateLineItem: updateLineItem.mutateAsync,
		removeLineItem: removeLineItem.mutateAsync,
		addShippingMethod: addShipping.mutateAsync,
		createPaymentSessions: createPaymentSessions.mutateAsync,
		completeOrder: completeOrder.mutateAsync,
	};
}

export type { Cart, UseCartResult };
