import { ImageWithFallback } from '@src/modules/shared/components/image-with-fallback';
import Link from 'next/link';
import type { ReactElement } from 'react';

interface SearchResultProps {
	result: any;
}

export function SearchResult({ result }: SearchResultProps): ReactElement {
	return (
		<Link //
			href={`/products/${result.handle}/${result.id}`}
			className="flex items-center gap-x-4 p-2 text-left text-current no-underline hover:bg-primary/20">
			<span className="w-9 flex-none">
				<ImageWithFallback src={result.thumbnail} alt={`${result.title} thumbnail`} />
			</span>
			<span className="grow text-xs">{result.title}</span>
		</Link>
	);
}
