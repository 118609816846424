import config from '@src/lib/config';
import { useLocalStorage } from 'usehooks-ts';

interface UseRegionResult {
	region?: string;
	setRegion: (id: string) => void;
	clearRegion: () => void;
}

export function useRegion(): UseRegionResult {
	const [region, setRegion] = useLocalStorage('medusa-region-id', config.defaultRegion);

	return {
		region,
		setRegion: (id: string) => setRegion(id),
		clearRegion: () => setRegion(undefined),
	};
}
