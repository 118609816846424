import Head from 'next/head';
import type { ReactElement } from 'react';

// prettier-ignore
const defaults = {
  title: "MANN+HUMMEL | Leadership in filtration",
  description: "As a global leader and expert in the field of filtration MANN+HUMMEL develops innovative solutions for the health and mobility of people. A wide range of products and services enables us to meet the needs of our customers and society.",
  image: "https://www.mann-hummel.com/content/dam/website/mann-hummel/brands/englisch/ls-e-life-sciences-and-environment/life-science-and-environment-filters.jpg"
};

export function SeoTags(props: { title?: string; description?: string; image?: string }): ReactElement {
	const { title, description, image } = props;
	return (
		<Head>
			<title>{title || defaults.title}</title>
			<meta property="og:title" content={title || defaults.title} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={image || defaults.image} />
			<meta property="og:url" content="https://www.mann-hummel.com/en.html" />
			<meta property="og:description" content={description || defaults.description} />
			<meta property="og:site_name" content="MANN+HUMMEL" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title || defaults.title} />
			<meta name="twitter:description" content={description || defaults.description} />
			<meta name="twitter:image" content={image || defaults.image} />
		</Head>
	);
}
