// noinspection HtmlRequiredAltAttribute

import { Skeleton } from '@src/modules/shared/components/skeleton';
import type { ComponentPropsWithoutRef, ReactElement } from 'react';
import { useEffect, useState } from 'react';

const placeholder = '/placeholder.svg';

type ImageProps = Omit<ComponentPropsWithoutRef<'img'>, 'src'> & {
	src?: string | null;
};

export function ImageWithFallback({
	alt,
	src: actualSrc,
	fallbackSrc = placeholder,
	...rest
}: ImageProps & {
	fallbackSrc?: string;
}): ReactElement | null {
	const [src, setSrc] = useState('');

	useEffect(() => {
		if (actualSrc) {
			const img = document.createElement('img');
			img.onload = (): void => setSrc(actualSrc);
			img.onerror = (): void => setSrc(fallbackSrc);
			img.src = actualSrc;
		}
	}, [actualSrc, fallbackSrc]);

	if (!src) {
		return <Skeleton className="aspect-square" aria-label="Loading image..." />;
	}

	return <img alt={alt ?? 'image'} src={src} {...rest} />;
}
