import type { StorePostSearchRes } from '@medusajs/medusa';
import { medusaClient } from '@src/lib/medusa/medusa-client';
import { queryKeys } from '@src/lib/react-query/query-keys';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { toast } from 'react-toastify';

interface UseSearchResult {
	query: string;
	isBusy: boolean;
	setQuery: (query: string) => void;
	result: UseQueryResult<StorePostSearchRes | null>;
}

interface SearchState {
	query: string;
	isBusy: boolean;
}

type SearchStateAction =
	| { type: 'BUSY' } //
	| { type: 'READY' }
	| { type: 'QUERY'; payload: string };

function stateReducer(state: SearchState, action: SearchStateAction) {
	switch (action.type) {
		case 'BUSY':
			return { ...state, isBusy: true };
		case 'READY':
			return { ...state, isBusy: false };
		case 'QUERY':
			return { ...state, query: action.payload };
		default:
			return state;
	}
}

const initialState: SearchState = { query: '', isBusy: false };
const searchAtom = atomWithReducer(initialState, stateReducer);

export function useSearch(): UseSearchResult {
	const [state, dispatch] = useAtom(searchAtom);

	const result = useQuery<StorePostSearchRes | null>(
		[queryKeys.searchProducts, state.query],
		async () => {
			try {
				if (state.query) {
					dispatch({ type: 'BUSY' });
					return await medusaClient.products.search({
						q: state.query,
						limit: 10,
					});
				}
			} catch (e: any) {
				toast.error(e?.message, {
					toastId: 'search-products-error',
				});
			} finally {
				dispatch({ type: 'READY' });
			}

			return null;
		},
		{ enabled: !!state.query },
	);

	return {
		query: state.query,
		isBusy: state.isBusy,
		setQuery: (query: string) => dispatch({ type: 'QUERY', payload: query }),
		result,
	};
}
