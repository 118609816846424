/* eslint-disable jsx-a11y/no-autofocus */
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearch } from '@src/modules/products/search/use-search';
import { FieldError } from '@src/modules/shared/components/forms/field-error';
import { Input } from '@src/modules/shared/components/forms/input';
import { IconSearch } from '@tabler/icons-react';
import type { ReactElement } from 'react';
import { useCallback, useId } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
	search: '',
};

const schema = yup.object().shape({
	search: yup //
		.string()
		.required('Please enter a search term.')
		.min(3, 'Search must be at least 3 characters.'),
});

export function SearchForm(): ReactElement | null {
	const id = useId();
	const { query, setQuery } = useSearch();

	const form = useForm({
		defaultValues: {
			...defaultValues,
			search: query,
		},
		resolver: yupResolver(schema),
	});

	const {
		register,
		formState: { errors },
	} = form;

	const doSearch = useCallback(
		async (data: typeof defaultValues): Promise<void> => {
			setQuery(data.search);
		},
		[setQuery],
	);

	return (
		<form onSubmit={form.handleSubmit(doSearch)}>
			<Input //
				autoFocus
				variant="small"
				aria-label="Search products..."
				placeholder="Search products"
				aria-invalid={!!errors?.search?.message}
				aria-errormessage={`${id}-search-error`}
				rightIcon={<IconSearch className="text-gray-300" />}
				{...register('search')}
			/>
			<FieldError id={`${id}-search-error`}>{errors.search?.message}</FieldError>
		</form>
	);
}
