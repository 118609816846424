import { useCart } from '@src/lib/medusa/hooks/use-cart';
import { SearchProducts } from '@src/modules/products/search';
import { IconShoppingCart } from '@tabler/icons-react';
import clsx from 'clsx';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { Logo } from '../logo';
import styles from './index.module.scss';

export function NavBar(): ReactElement {
	const { cart } = useCart();

	const itemsInCart =
		cart?.items.reduce((t, c) => {
			return t + c.quantity;
		}, 0) || 0;

	return (
		<div className={styles.navbar}>
			<div className="grid grid-cols-3 items-center text-xs leading-none">
				<div className="flex justify-self-start">
					<Link href="/products" className="text-current no-underline">
						Store
					</Link>
				</div>
				<div className="justify-self-center">
					<Link href="/" title="Home" className="text-current no-underline">
						<h1 className="m-0">
							<Logo className="h-8 w-auto" aria-hidden />
							<span className="sr-only">Mann+Hummel</span>
						</h1>
					</Link>
				</div>
				<div className="flex gap-x-2 justify-self-end md:gap-x-4 lg:gap-x-6">
					<SearchProducts />
					<Link href="/checkout/review" className="no-underline" title="My Cart">
						<IconShoppingCart />
						<span className="hidden sm:block">Cart</span>
						<span
							key={itemsInCart}
							aria-label="Cart Item Count"
							className={clsx('badge badge-primary', itemsInCart > 0 && styles.pulse)}
							style={{ position: 'relative', top: '-0.15em', fontSize: '87.5%' }}>
							{itemsInCart}
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
}
