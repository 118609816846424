import config from '@root/tailwind.config.cjs';
import type { Config } from 'tailwindcss';
import resolveConfig from 'tailwindcss/resolveConfig';

const coerced = config as unknown as Config;
const tailwind = resolveConfig(coerced);

export function useTailwindTheme(): typeof tailwind.theme {
	return tailwind.theme;
}
