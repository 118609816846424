const textShadow = require('./text-shadow');

module.exports = {
	theme: {
		container: {
			center: true,
		},
		extend: {
			colors: {
				white: '#fafafa',
				black: '#141414',
				muted: '#565c65',
				primary: {
					DEFAULT: '#00823C',
					50: '#00F571',
					100: '#00E86B',
					200: '#00CE5F',
					300: '#00B554',
					400: '#009B48',
					500: '#00823C',
					600: '#006E33',
					700: '#005929',
					800: '#004520',
					900: '#003016',
					950: '#002612',
				},
				secondary: {
					DEFAULT: '#6B7280',
					50: '#C1C4CB',
					100: '#B7BBC3',
					200: '#A4A8B3',
					300: '#9096A2',
					400: '#7D8492',
					500: '#6B7280',
					600: '#5D636F',
					700: '#4F545F',
					800: '#41464E',
					900: '#33373D',
					950: '#2C2F35',
				},
				info: {
					DEFAULT: '#2A669F',
					50: '#E4F7F8',
					100: '#CCEEF2',
					200: '#9CD7E5',
					300: '#6CB9D8',
					400: '#3B94CB',
					500: '#2A669F',
					600: '#234B83',
					700: '#1B3366',
					800: '#14204A',
					900: '#0C102E',
				},
				warning: {
					DEFAULT: '#FFC857',
					50: '#FFF7E6',
					100: '#FFF0D1',
					200: '#FFE3A9',
					300: '#FFD580',
					400: '#FFC857',
					500: '#FFB61F',
					600: '#E69B00',
					700: '#AE7500',
					800: '#764F00',
					900: '#3E2900',
					950: '#211600',
				},
				danger: {
					DEFAULT: '#9F2A2A',
					50: '#F8EBE4',
					100: '#F2D7CC',
					200: '#E5AC9C',
					300: '#D87B6C',
					400: '#CB463B',
					500: '#9F2A2A',
					600: '#83232A',
					700: '#661B26',
					800: '#4A1420',
					900: '#2E0C16',
					950: '#200810',
				},
				success: {
					DEFAULT: '#008243',
					50: '#7FFFBE',
					100: '#63FFB0',
					200: '#2BFF95',
					300: '#00F27A',
					400: '#00BA5F',
					500: '#008243',
					600: '#006E39',
					700: '#00592F',
					800: '#004524',
					900: '#00301A',
					950: '#002615',
				},
				dark: {
					DEFAULT: '#2D2F2D',
					50: '#868D88',
					100: '#7C837E',
					200: '#686E69',
					300: '#545955',
					400: '#404441',
					500: '#2D2F2D',
					600: '#252726',
					700: '#1E201E',
					800: '#161817',
					900: '#0F100F',
					950: '#0B0C0B',
				},
			},
			borderColor: {
				DEFAULT: '#cccccc',
			},
			borderRadius: {
				DEFAULT: '0.2rem',
			},
			textShadow: {
				sm: '0 1px 2px var(--tw-shadow-color)',
				DEFAULT: '0 2px 4px var(--tw-shadow-color)',
				lg: '0 8px 16px var(--tw-shadow-color)',
			},
		},
	},
	plugins: [textShadow],
};
