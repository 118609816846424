import clsx from 'clsx';
import type { ComponentPropsWithRef, ReactElement } from 'react';
import { forwardRef } from 'react';

import styles from './input.module.scss';

interface InputProps extends ComponentPropsWithRef<'input'> {
	variant?: 'small' | 'large' | 'extra-small';
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
	const {
		variant,
		leftIcon,
		rightIcon,
		className,
		'aria-invalid': invalid,
		...rest //
	} = props;

	return (
		<div
			data-invalid={invalid}
			className={clsx(
				styles.wrapper, //
				variant && styles[`wrapper-${variant}`],
			)}>
			{leftIcon}
			<input //
				ref={ref}
				aria-invalid={invalid}
				className={clsx(
					styles.input,
					className, //
				)}
				{...rest}
			/>
			{rightIcon}
		</div>
	);
});
