import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';

import styles from './field-error.module.scss';

export const FieldError = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(function FieldError(props, ref) {
	const { className, children, ...rest } = props;
	return (
		<div ref={ref} className={clsx(className, styles.fieldError)} {...rest}>
			{children}
		</div>
	);
});
