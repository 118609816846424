import type { ComponentPropsWithoutRef, ReactElement } from 'react';

export function Logo(props: ComponentPropsWithoutRef<'svg'>): ReactElement {
	return (
		<svg //
			enableBackground="new -8.433 62.5 132.865 49"
			height="49"
			viewBox="-8.433 62.5 132.865 49"
			width="132.865"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<path
				d="m105.866 109.563v-20.897h4.55v16.717h10.402v4.086h-14.952zm-3.994-24.982h-4.363v-8.359h-8.543v-4.363h8.543v-8.266h4.363v8.266h8.544v4.363h-8.544zm0 4.085v4.088h-11.328v4.271h10.122v4.085h-10.122v4.365h11.422v4.087h-15.972v-20.803h15.878zm-24.423 7.245-5.665 8.915-5.572-8.823v13.56h-4.457v-20.897h4.828l5.292 8.822 5.204-8.822h4.923v20.991h-4.551zm-10.122-24.609v13.279h-4.55v-20.895h4.272l10.309 12.816v-12.816h4.55v20.896h-3.9zm-23.125 0v13.279h-4.55v-20.895h4.272l10.308 12.816v-12.816h4.551v20.896h-3.901zm-13.187 8.636h-9.101l-1.857 4.644h-4.644l9.009-21.082h4.271l9.008 21.081h-4.736zm-4.55-10.774-2.972 6.688h5.85zm-6.685 31.946c0 2.323 1.485 4.552 4.271 4.552h.373c2.878 0 4.271-2.229 4.271-4.552v-12.351h4.551v12.167c0 2.972-1.393 9.008-8.452 9.008h-1.208c-5.943 0-8.543-4.365-8.543-9.008v-12.167h4.644v12.352h.093zm-10.773-30.181-5.943 8.915-5.944-8.823v13.563h-4.458v-20.896h4.922l5.572 8.822 5.387-8.822h4.922v20.989h-4.551v-13.746zm-11.796 26.096h9.288v-8.359h4.55v20.991h-4.55v-8.454h-9.288v8.454h-4.55v-20.991h4.55zm50.429.463 5.107-8.822h4.922v20.991h-4.551v-13.746l-5.665 8.916-5.572-8.823v13.561h-4.55v-20.897h4.922z"
				fill="#008243"
			/>
		</svg>
	);
}
