import { PageFooter } from '@src/modules/shared/components/page-footer';
import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';

import { NavBar } from './nav-bar';

export function Layout({
	children, //
}: {
	children: ReactNode;
}): ReactElement {
	return (
		<Fragment>
			<header>
				<NavBar />
			</header>
			<main className="grow">{children}</main>
			<footer>
				<PageFooter />
			</footer>
		</Fragment>
	);
}
