import * as Dialog from '@radix-ui/react-dialog';
import { SearchForm } from '@src/modules/products/search/search-form';
import { SearchResult } from '@src/modules/products/search/search-result';
import { useSearch } from '@src/modules/products/search/use-search';
import { LoadingIndicator } from '@src/modules/shared/components/loading-indicator';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { Fragment, useCallback, useEffect, useReducer } from 'react';

type SearchProductsAction =
	| { type: 'toggle' } //
	| { type: 'close' }
	| { type: 'enter' }
	| { type: 'leave' };

interface SearchProductsState {
	isOpen: boolean;
	isInside: boolean;
}

function stateReducer(state: SearchProductsState, action: SearchProductsAction): SearchProductsState {
	switch (action.type) {
		case 'toggle':
			return {
				...state,
				isOpen: !state.isOpen,
			};
		case 'close':
			return {
				...state,
				isOpen: false,
			};
		case 'enter':
			return {
				...state,
				isInside: true,
			};
		case 'leave':
			return {
				...state,
				isInside: false,
			};
		default:
			return state;
	}
}

export function SearchProducts(): ReactElement {
	const router = useRouter();
	const { result, isBusy } = useSearch();
	const [state, dispatch] = useReducer(stateReducer, {
		isOpen: false,
		isInside: false,
	});

	const toggle = useCallback(() => {
		dispatch({ type: 'toggle' });
	}, []);

	const closeMe = useCallback(() => {
		dispatch({ type: 'close' });
	}, []);

	const closeIfOutside = useCallback(() => {
		if (!state.isInside) {
			dispatch({ type: 'close' });
		}
	}, [state.isInside]);

	useEffect(() => {
		router.events.on('routeChangeComplete', closeMe);
		return () => router.events.off('routeChangeComplete', closeMe);
	}, [closeMe, router]);

	return (
		<Dialog.Root open={state.isOpen} onOpenChange={toggle}>
			<Dialog.Trigger asChild>
				{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
				<button title="Search Products">
					<IconSearch />
					<span className="hidden lg:block">Search</span>
				</button>
			</Dialog.Trigger>
			<Dialog.Portal>
				<Dialog.Overlay className="fixed inset-0 bg-black/50" />
				<Dialog.Content //
					onClick={closeIfOutside}
					className="fixed inset-0 mx-auto flex items-start justify-center">
					<div //
						onMouseEnter={() => dispatch({ type: 'enter' })}
						onMouseLeave={() => dispatch({ type: 'leave' })}
						className="relative m-6 max-h-[90vh] w-[36rem] max-w-[90vw] overflow-y-auto rounded bg-white p-4 shadow-lg">
						<Dialog.Title className="text-base font-normal">Search Products</Dialog.Title>
						<Dialog.Close className="absolute right-2 top-2 z-10">
							<IconX className="h-4 w-4 text-current" />
						</Dialog.Close>
						<SearchForm />
						<div className="relative mt-4 flex h-full min-h-[8rem] flex-col gap-1">
							{isBusy && (
								<div className="absolute inset-0 z-20 flex items-center justify-center bg-white/50">
									<LoadingIndicator />
								</div>
							)}
							<Fragment>
								<div className="mb-2 text-center text-[60%] text-gray-600">
									{result.fetchStatus === 'fetching' ? (
										<span>Loading...</span>
									) : result.status === 'error' ? (
										<span>Error loading search results.</span>
									) : result.data ? (
										<Fragment>
											{result.data.hits.length > 0 ? (
												result.data.hits.map((hit: any) => <SearchResult key={hit.handle} result={hit} />)
											) : (
												<span>Nothing found. Try a different query?</span>
											)}
										</Fragment>
									) : (
										<span>Type something to search.</span>
									)}
								</div>
							</Fragment>
						</div>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
