const plugin = require('tailwindcss/plugin');

module.exports = plugin(function ({ matchUtilities, theme }) {
	const values = theme('textShadow', {
		sm: '0 1px 2px var(--tw-shadow-color)',
		DEFAULT: '0 2px 4px var(--tw-shadow-color)',
		lg: '0 8px 16px var(--tw-shadow-color)',
	});

	matchUtilities(
		{
			'text-shadow': (value) => ({
				textShadow: value,
			}),
		},
		{ values },
	);
});
